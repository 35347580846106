import React, {useEffect, useState} from 'react';
import { bookToFullImgSrc, bookKeyToDbName } from "../../Chat/components/utils/utils";

// oc has type: 
// {
//     bookie?: string,
//     odds?: number,
//     otherSideOdds?: number,
// }


const BOOKIES = [
    'prophetx',
    'novig',
    'sporttrade',
    'fanduel',
    'draftkings',
    'espn bet',
    'pinnacle',
    'betmgm',
    'betrivers',
    'caesars',
    'fliff',
    'pointsbet',
    'betopenly',
    'circa',
    'fanatics',
    'bet365',
    'hardrock',
    'betonline',
];

const EditableOutcome = ({oc, updateHandler, removeHandler, isMain = false}) => {
    const [showBookieModal, setShowBookieModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Handle escape key
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setShowBookieModal(false);
            }
        };

        // Handle clicks outside
        const handleClickOutside = (event) => {
            if (showBookieModal && !event.target.closest('.bookie-modal-content')) {
                setShowBookieModal(false);
            }
        };

        document.addEventListener('keydown', handleEscape);
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup
        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showBookieModal]);

    const filteredBookies = BOOKIES.filter(bookie =>
        bookie.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={`gap-1 w-fit flex flex-col h-fit border border-2 p-2 rounded-2xl text-center ${isMain ? 'border-yellow-300 py-4 p-2' : 'dark:border-dark-border-primary mb-2 mr-2 pb-1 p-2'}`}>
            <div className="relative">
                {!isMain && (
                    <button 
                        className="absolute -top-4 -right-4 bg-gray-200 dark:bg-dark-bg-primary rounded-full p-1 hover:bg-gray-300 dark:hover:bg-dark-bg-secondary"
                        onClick={removeHandler}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                )}
                <div className="cursor-pointer" onClick={() => setShowBookieModal(true)}>
                    {!oc.bookie && <div className={`mx-auto bg-gray-200 rounded-lg ${isMain ? 'h-16 w-16' : 'h-12 w-12'}`}></div>}
                    {oc.bookie && <img src={bookToFullImgSrc(oc.bookie)} alt={oc.bookie} className={`mx-auto rounded-md ${isMain ? 'h-16 w-16' : 'h-12 w-12'}`} />}
                </div>

                {showBookieModal && (
                    <div className="bookie-modal-content absolute left-full ml-2 top-0 bg-white dark:bg-dark-bg-primary p-4 rounded-lg shadow-lg border border-gray-200 dark:border-dark-border-primary z-50">
                        <input
                            type="text"
                            autoFocus
                            placeholder="Search..."
                            className="w-full mb-4 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="grid grid-cols-3 gap-2 w-fit min-w-[144px]">
                            <div 
                                key="empty" 
                                className="h-12 w-12 cursor-pointer hover:scale-110 transition-transform border border-gray-300 dark:border-dark-border-primary rounded-lg flex items-center justify-center"
                                onClick={() => {
                                    updateHandler({...oc, bookie: null});
                                    setShowBookieModal(false);
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            {filteredBookies.map(bookie => (
                                <div key={bookie} className={`h-12 w-12 cursor-pointer hover:scale-110 transition-transform ${oc.bookie === bookie ? 'border-yellow-300 border-2' : 'border-0'} dark:border-dark-border-primary rounded-lg`}>
                                    <img 
                                        src={bookToFullImgSrc(bookie)}
                                        alt={bookie}
                                        className="h-full w-full object-contain rounded-md border border-gray-200 dark:border-dark-border-primary"
                                        onClick={() => {
                                            // TODO: Add logic to update the bookie
                                            updateHandler({...oc, bookie});
                                            setShowBookieModal(false);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                        <button 
                            className="mt-4 w-full bg-gray-200 py-2 rounded-lg hover:bg-gray-300"
                            onClick={() => setShowBookieModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>

            <div className="flex flex-row justify-around gap-0 items-center">
                <input
                    type="number"
                    placeholder="--"
                    className="border-half text-sm border-gray-300 font-normal px-0 py-0 w-20 text-center focus:outline-none focus:ring-2 focus:ring-blue-500 rounded"
                    value={oc.odds}
                    onChange={(e) => updateHandler({...oc, odds: e.target.value})}
                    onBlur={(e) => {
                        const numValue = parseFloat(e.target.value);
                        if (isNaN(numValue) || e.target.value === '') {
                            updateHandler({...oc, odds: null});
                        } else {
                            updateHandler({...oc, odds: numValue});
                        }
                    }}
                />
            </div>
            <div className="flex flex-row justify-around gap-0 items-center">
                <input
                    type="number"
                    placeholder="--"
                    className="border-half text-sm border-gray-300 font-normal px-0 py-0 w-20 text-center focus:outline-none focus:ring-2 focus:ring-blue-500 rounded"
                    value={oc.otherSideOdds}
                    onChange={(e) => updateHandler({...oc, otherSideOdds: e.target.value})}
                    onBlur={(e) => {
                        const numValue = parseFloat(e.target.value);
                        if (isNaN(numValue) || e.target.value === '') {
                            updateHandler({...oc, otherSideOdds: null});
                        } else {
                            updateHandler({...oc, otherSideOdds: numValue});
                        }
                    }}
                />
            </div>
        </div>
    )
}

const Scratchpad = ({targetBookie, ockey}) => {
    const DEFAULT_OUTCOME = {
        bookie: null,
        odds: null,
        otherSideOdds: null,
        id: Date.now(),
    }
    const [oc, setOc] = useState({...DEFAULT_OUTCOME, id: Date.now()});
    const [comparables, setComparables] = useState([{...DEFAULT_OUTCOME, id: Date.now()+1}, {...DEFAULT_OUTCOME, id: Date.now()+2}, {...DEFAULT_OUTCOME, id: Date.now()+3}]);
    const [weights, setWeights] = useState([]);
    const [selectedWeight, setSelectedWeight] = useState(''); // api_id
    const [kelly, setKelly] = useState(1);
    const [bankroll, setBankroll] = useState(null);

    const [probit, setProbit] = useState(null);
    const [multiplicative, setMultiplicative] = useState(null);
    const [additive, setAdditive] = useState(null);
    const [power, setPower] = useState(null);

    useEffect(() => {
        if (ockey) {
            reset();
            const body = {ockey};
            fetch(`/betslip/comparables`, {
                'method': 'POST',
                'body': JSON.stringify(body),
                'headers': {
                    'Content-Type': "application/json",
                    'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
                }
            }).then(res => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
                return res.json();
            }).then(data => {
                const dataWithIds = data.map((d, i) => ({...d, id: Date.now()+i})).sort((a, b) => b.odds - a.odds)
                const comparables = dataWithIds.filter(d => d.bookie !== targetBookie);
                const targetData = dataWithIds.find(d => d.bookie === targetBookie);
                if (!!targetData) {
                    setOc(targetData);
                } else {
                    setOc({...DEFAULT_OUTCOME});
                }
                setComparables(comparables);
            }).catch(err => {
                console.error(err);
                const statusCode = err.status;
                alert(`Error ${statusCode} occurred.`);
            });
        } else {
            fetch(`/profile/user/settings/scratchpad`, {
                'headers': {
                    'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
                }
            }).then(res => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
                return res.json();
            }).then(data => {
                if (data) {
                    // TODO: parse the data.
                    if (data.target) {
                        setOc(data.target);
                    }
                    if (data.comparables) {
                        setComparables(data.comparables);
                    }
                }
            }).catch(err => {
                console.error(err);
                const statusCode = err.status;
                alert(`Error ${statusCode} occurred.`);
            });
        }
    }, [ockey]);

    useEffect(() => {
        fetch(`/devig/weights`, {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setWeights(data);
        }).catch(err => {
            console.error(err);
        });
        // get the bet sizing settings.
        fetch('/profile/user/settings', {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            if (data.kelly) {
                const kellyValue = typeof data.kelly === 'string' ? parseFloat(data.kelly) : data.kelly;
                if (!isNaN(kellyValue)) {
                    setKelly(kellyValue);
                }
            }
            if (data.bankroll) {
                const bankrollValue = typeof data.bankroll === 'string' ? parseFloat(data.bankroll) : data.bankroll;
                if (!isNaN(bankrollValue)) {
                    setBankroll(bankrollValue);
                }
            }
        }).catch(err => {
            console.error(err);
        });

    }, []);

    useEffect(() => {
        // updateScratchpadState();
        const timeoutId = setTimeout(() => {
            updateScratchpadState();
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [oc, comparables]);

    const updateScratchpadState = () => {
        const locOc = {...oc, bookie: !oc.bookie ? null : bookKeyToDbName(oc.bookie)};
        const locComparables = comparables.map(c => ({...c, bookie: !c.bookie ? null : bookKeyToDbName(c.bookie)}));
        const body = {
            target: locOc,
            comparables: locComparables,
        }
        fetch(`/profile/user/settings/scratchpad`, {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            // console.log(data)
        }).catch(err => {
            console.error(err);
        });
    }

    const reset = () => {
        setOc({...DEFAULT_OUTCOME, id: Date.now()});
        setComparables([{...DEFAULT_OUTCOME, id: Date.now()+1}, {...DEFAULT_OUTCOME, id: Date.now()+2}, {...DEFAULT_OUTCOME, id: Date.now()+3}]);
        setSelectedWeight('');
        setProbit(null);
        setMultiplicative(null);
        setAdditive(null);
        setPower(null);
    }

    const updateMainOc = (newOc) => {
        setOc(newOc);
    };

    const updateComparableOc = (index, newOc) => {
        const newComparables = [...comparables];
        newComparables[index] = newOc;
        setComparables(newComparables);
    };

    const removeComparable = (index) => {
        const newComparables = comparables.filter((_, i) => i !== index);
        setComparables(newComparables);
    };

    const devig = () => {
        const locOc = {...oc, bookie: !oc.bookie ? null : bookKeyToDbName(oc.bookie)};
        const locComparables = comparables.map(c => ({...c, bookie: !c.bookie ? null : bookKeyToDbName(c.bookie)}));
        const body = {
            target: locOc,
            comparables: locComparables,
            weight_id: parseInt(selectedWeight) || null,
        }
        fetch(`/devig/v2`, {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setProbit(data.probit);
            setMultiplicative(data.multiplicative);
            setAdditive(data.additive);
            setPower(data.power);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    const computeKelly = (item) => {
        // item has fields ev, kelly, fv

        let kellyPrefix = kelly.toFixed(2) + ' Kelly: ';
        if (kelly === 1) {
            kellyPrefix = 'FK: ';
        } else if (kelly === 0.5) {
            kellyPrefix = 'HK: ';
        } else if (kelly === 0.25) {
            kellyPrefix = 'QK: ';
        }

        const locKelly = kelly * item.kelly;
        if (bankroll > 0) {
            const betAmount = (locKelly/100) * bankroll;
            return '$' + Math.floor(betAmount).toFixed(2);
        } else {
            return kellyPrefix + locKelly.toFixed(2);
        }
    }

    return (
        <div className="flex flex-col h-full">
            <div className="flex-1 overflow-y-auto">

                <div className="flex flex-row justify-start gap-2">
                    <div className="">
                        <EditableOutcome key={oc.id} oc={oc} updateHandler={updateMainOc} isMain={true} />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-row justify-between w-full">
                            <div className="flex flex-col mt-1">
                                <select className="cursor-pointer py-1 h-fit bg-white border border-gray-300 text-gray-900 text-sm rounded-lg 
                                    focus:ring-purple-500 focus:border-purple-500 block p-2"
                                    value={selectedWeight || ''}
                                    onChange={(e) => setSelectedWeight(e.target.value)}
                                >
                                    <option value="">Unweighted</option>
                                    {weights.map(w => (
                                        <option key={w.uaw_id} value={w.weight.api_id}>{w.name}</option>
                                    ))}
                                </select>
                            </div>
                            <button 
                                className="h-fit bg-gradient-to-r from-purple-500 to-purple-600 
                                text-white rounded-lg px-4 py-1.5 hover:from-purple-600 hover:to-purple-700 
                                text-sm font-semibold shadow-md transition-all duration-200 ease-in-out 
                                hover:shadow-lg active:scale-95 focus:outline-none focus:ring-2 
                                focus:ring-purple-500 focus:ring-opacity-50"
                                onClick={devig}
                            >
                                Devig
                            </button>
                        </div>
                        <div>
                            {probit && <p className="text-sm">Probit: <span className="font-bold">{probit.ev.toFixed(2)}%</span> <span className="text-gray-500 dark:text-dark-text-secondary">{probit.ev > 0 && computeKelly(probit)} ({probit.fv > 0 ? '+' + probit.fv : probit.fv})</span></p>}
                            {multiplicative && <p className="text-sm">Multiplicative: <span className="font-bold">{multiplicative.ev.toFixed(2)}%</span> <span className="text-gray-500 dark:text-dark-text-secondary">{multiplicative.ev > 0 && computeKelly(multiplicative)} ({multiplicative.fv > 0 ? '+' + multiplicative.fv : multiplicative.fv})</span></p>}
                            {additive && <p className="text-sm">Additive: <span className="font-bold">{additive.ev.toFixed(2)}%</span> <span className="text-gray-500 dark:text-dark-text-secondary">{additive.ev > 0 && computeKelly(additive)} ({additive.fv > 0 ? '+' + additive.fv : additive.fv})</span></p>}
                            {power && <p className="text-sm">Power: <span className="font-bold">{power.ev.toFixed(2)}%</span> <span className="text-gray-500 dark:text-dark-text-secondary">{power.ev > 0 && computeKelly(power)} ({power.fv > 0 ? '+' + power.fv : power.fv})</span></p>}
                        </div>
                    </div>
                </div>

                <div className="mt-2">
                    <div className="flex flex-wrap">
                        {comparables.map((comp, index) => {
                            return <EditableOutcome
                                key={comp.id}
                                oc={comp}
                                updateHandler={(newOc) => updateComparableOc(index, newOc)}
                                removeHandler={() => removeComparable(index)}
                            />
                        })}
                        <button
                            className="flex flex-row justify-center items-center h-12 w-12 hover:bg-gray-100 dark:hover:bg-dark-bg-primary-hover rounded-full my-auto"
                            onClick={() => setComparables([...comparables, {...DEFAULT_OUTCOME, id: Date.now()}])}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </button>

                    </div>
                </div>
            </div>

            <div className="p-2 border-t border-gray-200 dark:border-dark-border-primary">
                <button 
                    className="w-full bg-gray-200 dark:bg-dark-bg-primary rounded-lg px-4 py-1.5 hover:bg-gray-300 dark:hover:bg-dark-bg-primary-hover text-sm font-semibold shadow-md transition-all duration-200 ease-in-out hover:shadow-lg active:scale-95 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50" 
                    onClick={reset}
                >
                    Reset
                </button>
            </div>
        </div>
    )
}

export default Scratchpad;
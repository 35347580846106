
import fdFull from '../../../../images/books/fd.png';
import dkFull from '../../../../images/books/dk.png';
import pinnyFull from '../../../../images/books/pinny.png';
import mgmFull from '../../../../images/books/betmgm.png';
import barstoolFull from '../../../../images/books/espn.webp';
import kambiFull from '../../../../images/books/betrivers.webp';
import kalshiFull from '../../../../images/books/kalshi.png';
import pbFull from '../../../../images/books/pb.png';
import fnFull from '../../../../images/books/fanatics.png';
import hrFull from '../../../../images/books/hardrock.jpeg';
import fliffFull from '../../../../images/books/fliff.webp';
import b365Full from '../../../../images/books/b365.webp';
import novigFull from '../../../../images/books/novig.webp';
import prophetFull from '../../../../images/books/prophet.webp';
import bolFull from '../../../../images/books/betonline.png';
import czrFull from '../../../../images/books/czr.webp';
import bppFull from '../../../../images/books/bpp-black.jpg';
import circaFull from '../../../../images/books/circa.webp';
import betopenlyFull from '../../../../images/books/betopenly.webp';
import sporttradeFull from '../../../../images/books/sporttrade.webp';
import bookmakerFull from '../../../../images/books/bookmaker.jpg';

export function floored_cents_to_dollars(cents) {
    if (!cents) {
        return '';
    }
    return `$${Math.floor(cents / 100)}`;
}

export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substring(1);
        }
    );
}

export function prettyMktName(mkt) {
    if (mkt === 'pitcher_strikeout_over_under') {
        return 'Strikeouts'
    }
    const fixed = mkt.replaceAll('player_', '')
        .replaceAll('pitcher_', '')
        .replaceAll('batter_', '')
        .replaceAll('goalie_', '')
        .replaceAll('_over_under', '')
        .replaceAll('yds', 'yards')
        .replaceAll('_', ' ');
    return toTitleCase(fixed);
}


export function teamToLogoSrc(team) {
    team = team.toLowerCase();
    let src = '';
    switch (team) {
        case 'texas rangers':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/rangers.svg';
            break;
        case 'new york yankees':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/yankees.svg';
            break;
        case 'philadelphia phillies':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/phillies.svg';
            break;
        case 'los angeles angels':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/angels.svg';
            break;
        case 'pittsburgh pirates':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/pirates.svg';
            break;
        case 'kansas city royals':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/royals.svg';
            break;
        case 'chicago white sox':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/whitesox.svg';
            break;
        case 'boston red sox':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/redsox.svg';
            break;
        case 'milwaukee brewers':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/brewers.svg';
            break;
        case 'chicago cubs':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/cubs.svg';
            break;
        case 'st. louis cardinals':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/cardinals.svg';
            break;
        case 'atlanta braves':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/braves.svg';
            break;
        case 'new york mets':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/mets.svg';
            break;
        case 'san francisco giants':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/giants.svg';
            break;
        case 'los angeles dodgers':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/dodgers.svg';
            break;
        case 'colorado rockies':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/rockies.svg';
            break;
        case 'seattle mariners':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/mariners.svg';
            break;
        case 'washington nationals':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/nationals.svg';
            break;
        case 'baltimore orioles':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/orioles.svg';
            break;
        case 'oakland athletics':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/athletics.svg';
            break;
        case 'cincinnati reds':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/reds.svg';
            break;
        case 'arizona diamondbacks':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/dbacks.svg';
            break;
        case 'detroit tigers':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/tigers.svg';
            break;
        case 'miami marlins':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/marlins.svg';
            break;
        case 'cleveland guardians':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/guardians.svg';
            break;
        case 'minnesota twins':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/twins.svg';
            break;
        case 'toronto blue jays':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/jays.svg';
            break;
        case 'houston astros':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/astros.svg';
            break;
        case 'tampa bay rays':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/rays.svg';
            break;
        case 'san diego padres':
            src = 'https://mcwxlegantbmzk8stzntftdo.nyc3.cdn.digitaloceanspaces.com/static/logos/mlb/padres.svg';break;
    }
    return src;
}

export function oddsString(odds) {
    if (odds < 0) {
        return `-${Math.abs(odds)}`;
    }
    return `+${odds}`;
}

export function bookToFullImgSrc(s) {
    s = s.toLowerCase();
    let src = null;
    switch (s) {
        case 'pinnacle':
            src = pinnyFull; break;
        case 'fanduel':
            src = fdFull; break;
        case 'draftkings':
            src = dkFull; break;
        case 'betmgm':
            src = mgmFull; break;
        case 'barstool':
        case 'espn bet':
            src = barstoolFull; break;
        case 'betrivers':
        case 'kambi':
            src = kambiFull; break;
        case 'kalshi':
            src = kalshiFull; break;
        case 'caesars':
            src = czrFull; break;
        case 'pointsbet':
            src = pbFull; break;
        case 'sporttrade':
            src = sporttradeFull; break;
        case 'bookmaker':
            src = bookmakerFull; break;
        case 'betopenly':
            src = betopenlyFull; break;
        case 'circa':
            src = circaFull; break;
        case 'fanatics':
            src = fnFull; break;
        case 'hard rock':
        case 'hardrock':
            src = hrFull; break;
        case 'fliff':
            src = fliffFull; break;
        case 'bet365':
            src = b365Full; break;
        case 'betonline':
            src = bolFull; break;
        case 'novig':
            src = novigFull; break;
        case 'prophetx':
            src = prophetFull; break;
        case 'ballparkpal':
            src = bppFull; break;
    }
    return src;
}

export function bookToBg(book) {
    book = book.toLowerCase();
    switch (book) {
        case 'pinnacle':
            return 'rgb(255, 85, 0)';
        case 'fanduel':
            return 'rgb(47, 110, 227)';
        case 'draftkings':
            return 'rgb(120, 208, 82)';
        case 'betmgm':
            return 'rgb(213, 192, 122)';
        case 'barstool':
        case 'espn bet':
            return 'rgb(115, 240, 204)';
        case 'betrivers':
        case 'kambi':
            return 'rgb(35, 35, 150)';
        case 'caesars':
            return 'rgb(31, 83, 51)';
        case 'circa':
            return 'rgb(41, 51, 130)';
        case 'pointsbet':
            return 'rgb(218, 56, 72)';
        case 'sporttrade':
            return 'rgb(0, 134, 90)';
        case 'fanatics':
            return 'rgb(129, 24, 34)';
        case 'hardrock':
            return 'rgb(145, 124, 247)';
        case 'bet365':
            return 'rgb(251, 229, 81)';
        case 'betonline':
            return 'rgb(45, 46, 44)';
        case 'ballparkpal':
            return 'rgb(0, 0, 0)';
        case 'betopenly':
            return 'rgb(217, 56, 49)';
        case 'bookmaker':
            return 'rgb(255, 204, 0)';
        case 'kalshi':
            return 'rgb(0, 217, 145)';
        default:
            return 'rgb(229, 229, 229)';
    }
}

export function bookKeyToName(book) {
    book = book.toLowerCase().trim();
    switch (book) {
        case 'fanduel': return 'FanDuel';
        case 'draftkings': return 'DraftKings';
        case 'barstool': return 'ESPN BET';
        case 'pinnacle': return 'Pinnacle';
        case 'betmgm': return 'BetMGM';
        case 'betrivers': return 'BetRivers';
        case 'caesars': return 'Caesars';
        case 'fliff': return 'Fliff';
        case 'pointsbet': return 'PointsBet';
        case 'sporttrade': return 'Sporttrade';
        case 'bookmaker': return 'BookMaker';
        case 'betopenly': return 'BetOPENLY';
        case 'circa': return 'Circa';
        case 'fanatics': return 'Fanatics';
        case 'bet365': return 'Bet365';
        case 'hardrock': return 'Hard Rock';
        case 'betonline': return 'BetOnline';
        case 'novig': return 'Novig';
        case 'prophetx': return 'ProphetX';
        case 'ballparkpal': return 'Ballpark Pal';
        case 'kalshi': return 'Kalshi';
        default: return book;
    }
}
export function bookKeyToDbName(book) {
    book = book.toLowerCase().trim();
    switch (book) {
        case 'fanduel': return 'FanDuel';
        case 'draftkings': return 'DraftKings';
        case 'barstool': return 'Barstool';
        case 'pinnacle': return 'Pinnacle';
        case 'betmgm': return 'BetMGM';
        case 'betrivers': return 'BetRivers';
        case 'caesars': return 'Caesars';
        case 'fliff': return 'Fliff';
        case 'pointsbet': return 'PointsBet';
        case 'sporttrade': return 'Sporttrade';
        case 'bookmaker': return 'BookMaker';
        case 'betopenly': return 'BetOPENLY';
        case 'circa': return 'Circa';
        case 'fanatics': return 'Fanatics';
        case 'bet365': return 'Bet365';
        case 'hardrock': return 'Hardrock';
        case 'betonline': return 'BetOnline';
        case 'novig': return 'Novig';
        case 'prophetx': return 'ProphetX';
        case 'ballparkpal': return 'Ballpark Pal';
        case 'kalshi': return 'Kalshi';
        default: return book;
    }
}


export function americanToDec(odds) {
    if (odds > 0) {
        return (1 + (odds / 100.0));
    } else {
        return (1 + (100.0 / Math.abs(odds)));
    }
}

export function decToAmerican(odds) {
    if (odds >= 2) {
        return Math.round((odds - 1) * 100);
    } else {
        return Math.round(-100 / (odds - 1));
    }
}

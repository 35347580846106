import React, {useState} from 'react';
import pxLogoBlack from "../../../images/pxtextblack.svg";
import nvgLogoBlack from "../../../images/nvgtextblack.svg";
import pxLogoWhite from "../../../images/pxtextwhite.svg";
import nvgLogoWhite from "../../../images/nvgtextwhite.svg";
import trophyImg from "../../../images/trophy.png";
import cashImg from "../../../images/pcash.svg";
import nvgCashImg from "../../../images/novigcash.png";
import { toTitleCase, americanToDec } from "../../Chat/components/utils/utils";

const Play = ({play}) => {
    const [isLoadingShare, setIsLoadingShare] = useState(false);
    const isWin = play.status === 'win';
    const isLoss = play.status === 'loss';
    const isPending = play.status === 'pending';

    const computePayout = (odds, stake) => {
        const decOdds = americanToDec(odds);
        const payout = stake * decOdds;
        return payout.toFixed(2);
    }

    const genShareUrl = async () => {
        const atBookie = play.bookie === 'prophetx' ? '@PlayProphetX' : '@novig_app';
        const odds = play.odds > 0 ? '%2B' + play.odds : play.odds;
        let url = `https://twitter.com/intent/tweet?text=${play.eventTitle}%0A${play.marketTitle}%0A${play.outcomeTitle}%0A${odds}%20on%20${atBookie}%0A%0A&via=BeeBettor`;
        if (play.bookie !== 'prophetx' || !isPending) {
            return url;
        }
        
        // get the share link url...
        setIsLoadingShare(true);
        try {
            const body = {
                'bookie': play.bookie,
                'play': play.play,
            };
            
            const res = await fetch(`/wager/orderbook/play/deeplink`, {
                'method': 'POST',
                'body': JSON.stringify(body),
                'headers': {
                    'Content-Type': "application/json",
                    'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
                }
            });
    
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await res.json();
            if (data.deeplink) {
                url += '&url=' + encodeURIComponent(data.deeplink);
            }
            setIsLoadingShare(false);
            return url;
        } catch (err) {
            console.error(err);
            setIsLoadingShare(false);
            return url; // Return basic URL if there's an error
        }
    }

    return (
        <div key={play.id} className="flex flex-col border-half border-gray-200 dark:border-dark-border-primary m-2 bg-white dark:bg-dark-bg-primary aspect-square max-w-[95%] w-full md:w-96 h-fit hover:shadow-lg hover:scale-[1.01]">
            <div className="h-12 bg-gray-100 dark:bg-dark-bg-secondary pl-2 border-b border-gray-200 dark:border-dark-border-primary flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2 items-center">
                    {play.bookie === 'prophetx' && <img src={pxLogoBlack} className="dark:hidden" alt="ProphetX" height={24} width={92} />}
                    {play.bookie === 'novig' && <img src={nvgLogoBlack} className="dark:hidden" alt="Novig" height={24} width={96} />}
                    {play.bookie === 'prophetx' && <img src={pxLogoWhite} className="hidden dark:block" alt="ProphetX" height={24} width={92} />}
                    {play.bookie === 'novig' && <img src={nvgLogoWhite} className="hidden dark:block" alt="Novig" height={24} width={96} />}
                    
                    {isLoadingShare && <div data-join-target="loader" role="status" className="w-full h-full flex items-center">
                        <svg aria-hidden="true" className={`w-6 h-6 mx-auto text-transparent animate-spin fill-yellow-300`}
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"/>
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"/>
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>}
                    {!isLoadingShare && <p className="cursor-pointer text-sm text-gray-600 dark:text-dark-text-secondary font-medium underline"
                        onClick={async () => {
                            const shareUrl = await genShareUrl();
                            window.open(shareUrl, '_blank');
                        }}
                    >Share</p>}
                </div>
                {isWin && play.bookie === 'prophetx' && <div className="bg-[#75CBA8] h-full rounded-l-3xl py-2 pl-2 pr-1"><img src={trophyImg} alt="Trophy" height={32} width={32} /></div>}
                {isWin && play.bookie === 'novig' && <div className="bg-[#7dcefd] h-full rounded-l-3xl py-2 pl-2 pr-1"><img src={trophyImg} alt="Trophy" height={32} width={32} /></div>}
            </div>
            <div className="bg-gray-50 dark:bg-dark-bg-secondary flex flex-col gap-2 px-2 py-2">
                <div className="flex flex-row justify-between">
                    <p className="text-sm text-gray-700 dark:text-dark-text-secondary font-medium">{play.marketTitle}</p>
                    <p className="font-bold">{play.odds > 0 ? '+' + play.odds : play.odds}</p>
                </div>

                <div className="flex flex-row gap-2 items-center px-2">
                    {isWin && <span className="material-icons" style={{fontSize: '24px', color: '#22C55E'}}>check_circle</span>}
                    {isLoss && <span className="material-icons" style={{fontSize: '24px', color: '#EF4444'}}>cancel</span>}
                    {isPending && <span className="material-icons" style={{fontSize: '24px', color: '#808080'}}>radio_button_unchecked</span>}
                    <p className="text-sm text-black dark:text-white font-bold text-lg">{toTitleCase(play.outcomeTitle)}</p>
                </div>

                <div className="mt-4 flex flex-row justify-between gap-1">
                    <p className="font-medium text-gray-600 dark:text-dark-text-secondary text-xs">{play.eventTitle}</p>
                    <p className="text-xs text-gray-600 dark:text-dark-text-secondary">{new Date(play.eventTs).toLocaleString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true
                    })}</p>
                </div>
            </div>


            <div className="bg-gray-200 dark:bg-dark-bg-tertiary border-t border-gray-200 dark:border-dark-border-primary flex flex-row justify-between items-center p-2">
                <div className="flex flex-row gap-1 items-center">
                    {play.bookie === 'prophetx' && <img src={cashImg} alt="cash" height={32} width={32} className={isLoss ? 'grayscale' : ''} />}
                    {play.bookie === 'novig' && <img src={nvgCashImg} alt="cash" height={24} width={24} className={isLoss ? 'grayscale' : ''} />}
                    <div className={`text-gray-600 dark:text-dark-text-secondary font-semibold ${isLoss ? 'line-through' : ''}`}>{play.stake.toFixed(2)}</div>
                </div>
                <div className="">

                    {isWin && <div className="flex flex-row gap-1 items-center">
                        {play.bookie === 'prophetx' && <img src={cashImg} alt="cash" height={32} width={32} className={isLoss ? 'grayscale' : ''} />}
                        {play.bookie === 'novig' && <img src={nvgCashImg} alt="cash" height={24} width={24} className={isLoss ? 'grayscale' : ''} />}
                        <p className="text-lg text-green-500 font-black">{computePayout(play.odds, play.stake)}</p>
                    </div>}

                    {isPending && 
                        <div className="flex flex-col items-end">
                            <p className="text-xs text-gray-500">To Win</p>
                            <div className="flex flex-row gap-1 items-center">
                                {play.bookie === 'prophetx' && <img src={cashImg} alt="cash" height={32} width={32} className="" />}
                                {play.bookie === 'novig' && <img src={nvgCashImg} alt="cash" height={24} width={24} className="" />}
                                <p className="text-lg text-black dark:text-white font-bold">{computePayout(play.odds, play.stake)}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default Play;

import React, {useEffect, useState} from 'react';
import Loader from "../../Chat/components/utils/Loader";
import cashImg from "../../../images/pcash.svg";
import nvgCashImg from "../../../images/novigcash.png";

import Play from "./Play";

const History = () => {
    const [isLoadingHeader, setIsLoadingHeader] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(true);
    const [plays, setPlays] = useState([]);

    const [isPxConnected, setIsPxConnected] = useState(false);
    const [isNvgConnected, setIsNvgConnected] = useState(false);

    const [pxBalance, setPxBalance] = useState(null);
    const [nvgBalance, setNvgBalance] = useState(null);

    useEffect(() => {
        fetch('/profile/accounts/connected', {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setIsPxConnected(data.prophetx);
            setIsNvgConnected(data.novig);
            if (data.prophetx || data.novig) {
                getBalance();
                getPlays();
            } else {
                setIsLoadingHeader(false);
                setIsLoading(false);
            }
        }).catch(err => {
            console.error(err);
            // alert(`Error ${statusCode} occurred.`);
        });
    }, []);

    useEffect(() => {
        if (!isLoadingHeader) {
            getPlays();
        }
    }, [open]);

    const getBalance = () => {
        fetch('/wager/orderbook/balances', {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setPxBalance(data.prophetx);
            setNvgBalance(data.novig);
            setIsLoadingHeader(false);
        }).catch(err => {
            console.error(err);
            // alert(`Error ${statusCode} occurred.`);
        });
    }

    const getPlays = () => {
        setPlays([]);
        setIsLoading(true);
        fetch(`/wager/orderbook/plays?is_open=${open}`, {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            if (data.success) {
                setPlays(data.plays);
            }
            setIsLoading(false);
            // setHasNovigBeta(data.valid);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            // alert(`Error ${statusCode} occurred.`);
        });
    }

    const renderHeader = () => {
        if (isLoadingHeader) {
            return <div data-join-target="loader" role="status" className="w-fit h-full flex items-center">
                <svg aria-hidden="true" className={`w-8 h-8 mx-auto text-transparent animate-spin fill-yellow-300`}
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        }
        return (
            <div className="flex flex-row gap-4 items-center">
                {isPxConnected &&
                    <div className="flex flex-col gap-0 border-2 border-gray-200 dark:border-dark-border-primary rounded-lg p-2">
                        <p className="text-gray-500 text-xs dark:text-dark-text-secondary">ProphetX Balance</p>
                        <div className="flex flex-row gap-1 items-center">
                            <img src={cashImg} alt="cash" height={32} width={32} />
                            <div className="text-gray-600 dark:text-gray-200 font-bold">{pxBalance}</div>
                        </div>
                    </div>
                }
                {!isPxConnected &&
                    <a href="/profile/accounts">
                        <div className="hover:bg-gray-100 dark:hover:bg-dark-bg-secondary cursor-pointer flex flex-col gap-0 border-2 border-purple-500 rounded-lg p-2">
                            <div className="flex flex-row gap-1 items-center">
                                <img src={cashImg} alt="cash" height={32} width={32} />
                                <div className="text-gray-600 dark:text-gray-200 font-bold">Connect to ProphetX</div>
                            </div>
                        </div>
                    </a>
                }
                {isNvgConnected &&
                    <div className="flex flex-col gap-0 border-2 border-gray-200 dark:border-dark-border-primary rounded-lg p-2">
                        <p className="text-gray-500 text-xs dark:text-dark-text-secondary">Novig Balance</p>
                        <div className="flex flex-row gap-1 items-center">
                            <img src={nvgCashImg} alt="cash" height={24} width={24} />
                            <div className="text-gray-600 dark:text-gray-200 font-bold">{nvgBalance}</div>
                        </div>
                    </div>
                }
                {!isNvgConnected &&
                    <a href="/profile/accounts">
                        <div className="hover:bg-gray-100 dark:hover:bg-dark-bg-secondary cursor-pointer flex flex-col gap-0 border-2 border-purple-500 rounded-lg p-2">
                            <div className="flex flex-row gap-1 items-center">
                                <img src={nvgCashImg} alt="cash" height={24} width={24} />
                                <div className="text-gray-600 dark:text-gray-200 font-bold">Connect to Novig</div>
                            </div>
                        </div>
                    </a>
                }
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 mb-4 min-h-screen">
            <div className="flex flex-col md:flex-row justify-between items-center pl-4 pr-12 gap-4">
                <div className="flex flex-col gap-1 md:text-left text-center">
                    <h1 className="mx-auto md:mx-0 flex flex-row text-center md:text-left"><span className="font-semibold text-4xl">Orders</span></h1>
                    <h2 className="text-gray-600 dark:text-dark-text-secondary">View your order history from <a href="https://www.prophetx.co" target="_blank" rel="noopener noreferrer" className="underline">ProphetX</a> and <a href="https://www.novig.us" target="_blank" rel="noopener noreferrer" className="underline">Novig</a>.</h2>
                </div>

                {renderHeader()}
            </div>
            <hr className="border-gray-200 dark:border-dark-border-primary"/>

            <div className="mx-auto md:mx-0 px-4">
                <div className="border border-gray-200 dark:border-dark-border-primary flex flex-row rounded-lg w-48 h-fit">
                    <button 
                        onClick={() => setOpen(true)}
                        className={`h-fit w-1/2 cursor-pointer text-center py-2 font-bold rounded-lg ${open ? 'bg-yellow-300 text-black hover:bg-yellow-400' : 'hover:bg-gray-100 dark:hover:bg-dark-bg-secondary'}`}
                    >
                        Open
                    </button>

                    <button
                        onClick={() => setOpen(false)}
                        className={`h-fit w-1/2 cursor-pointer text-center py-2 font-bold rounded-lg ${!open ? 'bg-yellow-300 text-black hover:bg-yellow-400' : 'hover:bg-gray-100 dark:hover:bg-dark-bg-secondary'}`}
                    >
                        Closed 
                    </button>
                </div>
            </div>

            <div className={`h-full flex flex-row pb-8 ${(isLoading || plays.length === 0) ? 'items-center flex-1' : ''}`}>
                {isLoading && <Loader />}
                {!isLoading && !isPxConnected && !isNvgConnected &&
                    <div className="flex flex-col items-center gap-4 w-full">
                        <span className="material-icons" style={{fontSize: '96px', color: 'gray'}}>power_off</span>
                        <p className="text-gray-600 dark:text-dark-text-secondary">Connect to ProphetX or Novig to view your order history.</p>
                    </div>
                }
                {!isLoading && (isPxConnected || isNvgConnected) && plays.length === 0 &&
                    <div className="flex flex-col items-center gap-4 w-full">
                        <span className="material-icons" style={{fontSize: '96px', color: 'gray'}}>search_off</span>
                        <p className="text-gray-600 dark:text-dark-text-secondary">No orders found.</p>
                    </div>
                }
                <div className="flex flex-wrap justify-center">
                    {!isLoading && plays.length > 0 && plays.map((play) => <Play key={play.id} play={play} />)}
                </div>
            </div>
        </div>
    )
}



// autoSettled
// : 
// null
// averageOdds
// : 
// "+100"
// children
// : 
// []
// commission
// : 
// 0
// createdAt
// : 
// "2024-12-11T21:09:44+0000"
// displayLine
// : 
// "12.5"
// displayOdds
// : 
// "+100"
// id
// : 
// 64430758
// isHotData
// : 
// false
// line
// : 
// "12.5"
// market
// : 
// {id: 150001853, name: 'Josh Hart Total Points', type: 'total'}
// marketLineName
// : 
// "Josh Hart Total Points"
// matchedDetails
// : 
// [{…}]
// matchedStake
// : 
// 11.82
// matchingStatus
// : 
// "fully_matched"
// odds
// : 
// 100
// outcome
// : 
// {id: 12, name: 'over 12.5'}
// refId
// : 
// "partner_9745f15c-6749-4a66-8a4c-efa5c39afdbc"
// sport
// : 
// {name: 'Basketball'}
// sportEvent
// : 
// {id: 20022238, status: 'live', type: null, name: 'Atlanta Hawks at New York Knicks', displayName: '', …}
// stake
// : 
// 11.82
// status
// : 
// "open"
// totalBets
// : 
// 1
// totalStake
// : 
// 0
// tournament
// : 
// {name: 'NBA'}
// unmatchedStake
// : 
// 0
// winningStatus
// : 
// "tbd"

export default History;